import * as React from "react";

import Page from "../components/Layout/Page";
import Container from "../components/Layout/Container";

import BtnFilled from "../components/reusable/BtnFilled";
import Seo from "../components/reusable/Seo";

// markup
const NotFoundPage = () => {
  return (
    <Page className={"page-404"}>
      <Seo
        title="Page Not Found"
        path="404"
        description="Sorry, the page you are looking for doesn't exist or has been moved"
      />
      <section>
        <Container>
          <h1>
            <strong>404</strong>
          </h1>
          <p>
            Sorry, the page you are looking for doesn't exist or has been moved
          </p>
          <div className="links">
            <BtnFilled to="/">Go to Home Page</BtnFilled> <span>OR</span>{" "}
            <BtnFilled to="/contact-us">Contact Us</BtnFilled>
          </div>
        </Container>
      </section>
    </Page>
  );
};

export default NotFoundPage;
